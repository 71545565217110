import React from 'react';
import * as ReactGA from 'react-ga';
import Layout from '../components/Shared/Layout';
import How from '../components/How';

const $ = typeof window !== `undefined` ? require('jquery') : null;
class HowPage extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'How.Page',
    });
    ReactGA.pageview(this.props.location.pathname);
  }
  render() {
    return (
      <Layout>
        <How />
      </Layout>
    );
  }
}
export default HowPage;

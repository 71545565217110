import React from 'react';
import EngagementModels from '../Home/engagement_models';
import GrowthStages from './GrowthStages';
import '../../assets/css/how.css';

export default () => (
  <>
    <div className="how-container">
      <GrowthStages />
      <EngagementModels />
    </div>
  </>
);
